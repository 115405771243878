<template>
  <div class="bank-account">
    <h3>{{ $t('onboarding.bank_account.title') }}</h3>

    <b-form @submit.prevent="onSubmit">

      <b-row v-for="list in inputs">
        <b-col v-for="input in list" v-bind="$compute(input.col)"
               v-if="input.visible===undefined || $compute(input.visible)">
          <generic-input v-bind="input" :value="$g(form, input.key)"
                         @input="(event) => $s(form, input.key, event)"/>
        </b-col>
      </b-row>

      <b-button class="m-b block full-width" type="submit" variant="primary">
        {{ $t('button.save') }}
      </b-button>

    </b-form>
  </div>
</template>

<script>
import GenericInput from "@/components/generic_input.vue";
import {mapGetters} from "vuex";
import {putOnboardingClient} from "@/services/onboarding";
import store from "@/store";
import {getEmployee} from "@/services/employees";

export default {
  components: {GenericInput},
  beforeMount() {
    this.form.bank_account.account_owner_name = this.current_user.client.bank_account.account_owner_name
    this.form.bank_account.bank_name = this.current_user.client.bank_account.bank_name
    this.form.bank_account.iban = this.current_user.client.bank_account.iban
    this.form.bank_account.bic = this.current_user.client.bank_account.bic
  },
  data() {
    return {
      inputs: [
        [
          {
            key: 'bank_account.account_owner_name',
            label: 'common.account_owner_name',
            placeholder: 'Dein Name',
            required: true
          }
        ],
        [
          {
            key: 'bank_account.bank_name',
            label: 'common.bank_name',
            placeholder: 'Prolobank',
            required: true
          }
        ],
        [
          {
            key: 'bank_account.iban',
            label: 'common.iban',
            placeholder: 'DE12 0000 0000 0000 00',
            required: true
          }
        ],
        [
          {
            key: 'bank_account.bic',
            label: 'common.bic',
            placeholder: 'PROLOX42',
            required: true
          }
        ],
      ],
      form: {
        bank_account: {
          account_owner_name: null,
          bank_name: null,
          iban: null,
          bic: null
        }
      }
    }
  },
  computed: {
    ...mapGetters("user", ["current_user"]),
  },
  methods: {
    async onSubmit(event) {
      putOnboardingClient(this.current_user.client.id, this.form).then(response => {
        // download user to reflect changes
        getEmployee().then(response => {
          store.dispatch("user/setUser", response)
          this.$router.push({name: 'onboarding_dashboard'})
        })
      }).catch()
    },
  }
}
</script>

<style scoped lang="scss">

</style>


